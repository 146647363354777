$(document).ready(function () {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function () {
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function (e) {
        var target = $(this).attr("target"),
            url = $(this).attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    }).click(function () {
        var target = $(this).find("a.js-click-item-link").attr("target"),
            url = $(this).find("a.js-click-item-link").attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 80; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements() {
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function () {
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            } else {
                $(this).removeClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function () {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function () {
        if (location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        } else {
            $(this).attr('target', '_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-navbutton").click(function () {
        $(this).toggleClass("active");
        $(".js-nav").toggleClass("active");
        $(".js-header").toggleClass("active");
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * heroSlider
    // *
    // *
    var $heroSlider = $(".js-hero-slider");

    if ($heroSlider.length) {
        $heroSlider.each(function () {
            $(this).find(".js-hero-slider-track").slick({
                fade: true,
                infinite: true,
                arrows: true,
                autoplay: true,
                autoplaySpeed: 7000,
                speed: 1000,
                waitForAnimate: true,
                dots: true,
                pauseOnHover: false,
                appendDots: $(this).find(".js-hero-slider-dots"),
                prevArrow: $(this).find(".js-hero-slider-prev"),
                nextArrow: $(this).find(".js-hero-slider-next"),
                responsive: [
                    {
                        breakpoint: 1000,
                        settings: {
                            adaptiveHeight: true
                        }
                    }
                ]
            });
            $(this).on("init", () => {
                $(this).find("[id]").filter((_, element) => element.id === "").removeAttr("id");
            });
        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * signetText
    // *
    // *
    var $signetText = $(".js-signettext-slider");

    if ($signetText.length) {
        $signetText.each(function () {
            $(this).find(".js-signettext-slider-track").slick({
                fade: false,
                infinite: true,
                arrows: true,
                autoplay: false,
                autoplaySpeed: 2500,
                speed: 1000,
                waitForAnimate: true,
                dots: true,
                pauseOnHover: false,
                adaptiveHeight: true,
                appendDots: $(this).find(".js-signettext-slider-dots"),
                prevArrow: $(this).find(".js-signettext-slider-prev"),
                nextArrow: $(this).find(".js-signettext-slider-next"),
            });

            $(this).on("init", () => {
                $(this).find("[id]").filter((_, element) => element.id === "").removeAttr("id");
            });
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * contentSlider
    // *
    // *
    var $contentSlider = $(".js-content-slider");

    if ($contentSlider.length) {
        $contentSlider.each(function () {
            $(this).find(".js-content-slider-track").slick({
                fade: false,
                infinite: true,
                arrows: true,
                autoplay: false,
                autoplaySpeed: 2500,
                speed: 1000,
                waitForAnimate: true,
                dots: true,
                pauseOnHover: false,
                adaptiveHeight: true,
                appendDots: $(this).find(".js-content-slider-dots"),
                prevArrow: $(this).find(".js-content-slider-prev"),
                nextArrow: $(this).find(".js-content-slider-next"),
            });

            $(this).on("init", () => {
                $(this).find("[id]").filter((_, element) => element.id === "").removeAttr("id");
            });
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * iconSlider
    // *
    // *
    var $iconSlider = $(".js-icon-slider");

    if ($iconSlider.length) {
        $iconSlider.each(function () {
            $(this).find(".js-icon-slider-track").slick({
                fade: false,
                infinite: true,
                arrows: true,
                autoplay: false,
                autoplaySpeed: 2500,
                speed: 1000,
                waitForAnimate: true,
                dots: true,
                pauseOnHover: false,
                appendDots: $(this).find(".js-icon-slider-dots"),
                prevArrow: $(this).find(".js-icon-slider-prev"),
                nextArrow: $(this).find(".js-icon-slider-next"),
            });
            $(this).on("init", () => {
                $(this).find("[id]").filter((_, element) => element.id === "").removeAttr("id");
            });
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sliderQuote 
    // *
    // *
    var $sliderQuote = $(".js-sliderquote");

    if ($sliderQuote.length) {
        $sliderQuote.each(function () {
            $(this).slick({
                fade: false,
                infinite: true,
                arrows: false,
                autoplay: false,
                autoplaySpeed: 2500,
                speed: 1000,
                waitForAnimate: true,
                dots: true,
                pauseOnHover: false,
                // adaptiveHeight:true,
                appendDots: $(this).parents(".js-sliderquote-wrapper").find(".js-sliderquote-dots"),
                responsive: [
                    {
                        breakpoint: 950,
                        settings: {
                            adaptiveHeight: true
                        }
                    }
                ]
            });
            $(this).on("init", () => {
                $(this).find("[id]").filter((_, element) => element.id === "").removeAttr("id");
            });
        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * textImageSlider
    // *
    // *
    var $textImageSlider = $(".js-textimageslider");

    if ($textImageSlider.length) {
        $textImageSlider.each(function () {

            var $parent = $(this).parents(".js-textimageslider-wrapper");

            $(this).slick({
                fade: false,
                infinite: true,
                autoplay: false,
                speed: 1000,
                waitForAnimate: true,
                pauseOnHover: false,
                adaptiveHeight: true,
                dots: true,
                arrows: true,
                appendDots: $parent.find(".js-textimageslider-dots"),
                prevArrow: $parent.find(".js-textimageslider-prev"),
                nextArrow: $parent.find(".js-textimageslider-next"),
               
            });
            $(this).on("init", () => {
                $(this).find("[id]").filter((_, element) => element.id === "").removeAttr("id");
            });
        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * imageTeaserSlider
    // *
    // *
    var $imageTeaserSlider = $(".js-imageteaserslider");

    if ($imageTeaserSlider.length) {
        $imageTeaserSlider.each(function () {

            var $parent = $(this).parents(".js-imageteaserslider-wrapper");

            $(this).slick({
                fade: false,
                infinite: true,
                autoplay: false,
                speed: 1000,
                waitForAnimate: true,
                pauseOnHover: false,
                adaptiveHeight: false,
                dots: true,
                arrows: true,
                appendDots: $parent.find(".js-imagteaserslider-dots"),
                prevArrow: $parent.find(".js-imagteaserslider-prev"),
                nextArrow: $parent.find(".js-imagteaserslider-next"),
                responsive: [
                    {
                        breakpoint: 1000,
                        settings: {
                            adaptiveHeight: true
                        }
                    }
                ]
            });
            $(this).on("init", () => {
                $(this).find("[id]").filter((_, element) => element.id === "").removeAttr("id");
            });
        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion
    // *
    // *
    $(".js-accordion-header").click(function () {
        $(this).parents(".js-accordion-item").toggleClass("active");
        $(this).parents(".js-accordion-item").siblings().removeClass("active").find(".js-accordion-content").slideUp();
        $(this).siblings(".js-accordion-content").slideToggle();
        setTimeout(() => {
            $(this).get(0).scrollIntoView({ behavior: 'smooth' })
        }, 400);
    });

    // open accordion with anchor link
    let hash = window.location.hash.split('#')[1];
    if ($("#" + hash).hasClass("js-accordion-item") && !$("#" + hash).hasClass("open")) {
        $("#" + hash).find(".js-accordion-header").click();
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
    // * dialog window
    // *
    // *
    var $dialog = $(".js-dialog"),
        dialogState = sessionStorage.getItem('dialogState');

    $(".js-close-dialog").click(function () {
        $dialog.addClass("close");
        sessionStorage.setItem('dialogState', true);
        $(this).parents(".js-dialog").get(0).close();
        $("body").removeClass("freeze");
    });

    // backdrop click
    $(".js-dialog").click(function (e) {
        if (e.target === e.currentTarget) {
            $dialog.addClass("close");
            sessionStorage.setItem('dialogState', true);
            $(this).get(0).close();
            $("body").removeClass("freeze");
        }
    });

    if (!dialogState && $dialog.length > 0) {
        $dialog.get(0).showModal();
        $("body").addClass("freeze");
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
    // * product quantity
    // *
    // *
    $(".js-product-quantity-button-plus").click(function () {
        var $input = $(this).parents(".js-product-quantity").find(".js-product-quantity-input"),
            quantity = parseInt($input.val());

        $input.val(quantity + 1);
    });

    $(".js-product-quantity-button-minus").click(function () {
        var $input = $(this).parents(".js-product-quantity").find(".js-product-quantity-input"),
            quantity = parseInt($input.val()) - 1;

        $input.val((quantity <= 1) ? 1 : quantity);
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * header invert
    // *
    // *   
    const $header = $('.js-header');
    const $backgroundWhite = $('.backgroundWhite');

    function toggleHeaderClass() {
        let isInverted = false;

        $backgroundWhite.each(function () {
            const $this = $(this);
            const headerBottom = $header.offset().top + $header.outerHeight();
            const backgroundTop = $this.offset().top;
            const backgroundBottom = $this.offset().top + $this.outerHeight();

            // Check if the header is overlapping this `.backgroundWhite`
            if (headerBottom > backgroundTop && $header.offset().top < backgroundBottom) {
                isInverted = true;
                return false; // Exit the loop early since one match is enough
            }
        });

        if (isInverted) {
            $header.addClass('header--inverted');
        } else {
            $header.removeClass('header--inverted');
        }
    }

    // Attach scroll event listener
    $(window).on('scroll', toggleHeaderClass);

    // Initial check on page load
    toggleHeaderClass();

});



// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky Header
// *
// *
$(window).on("load scroll", function () {
    if ($(window).scrollTop() >= 170) {
        $(".js-header").addClass("showCenter");
    } else {
        $(".js-header").removeClass("showCenter");
    }
});

$(window).on('scroll', function () {
    const $header = $('.js-header');
    const $footer = $('.js-footer');

    // Get the bounding rectangles for both elements
    const headerRect = $header[0].getBoundingClientRect();
    const footerRect = $footer[0].getBoundingClientRect();

    // Check if the bottom of the header touches the top of the footer
    if (headerRect.bottom >= footerRect.top) {
        $header.addClass("inverted");
    }
    else {
        $header.removeClass("inverted");
    }
});


// * * * * * * * * * * * * * * * * * * * * * * * * *
// * instagramSlider
// *
// *
$(window).on('load resize', function () {
    if (window.innerWidth < 768) {
        var $instagramSlider = $(".js-instagram-slider");
        if ($instagramSlider.length) {
            $instagramSlider.each(function () {
                $(this).find(".js-instagram-slider-track").slick({
                    fade: false,
                    infinite: true,
                    arrows: true,
                    autoplay: false,
                    autoplaySpeed: 2500,
                    speed: 1000,
                    waitForAnimate: true,
                    dots: true,
                    pauseOnHover: false,
                    appendDots: $(this).find(".js-instagram-slider-dots"),
                    prevArrow: $(this).find(".js-instagram-slider-prev"),
                    nextArrow: $(this).find(".js-instagram-slider-next"),
                    responsive: [
                        {
                            breakpoint: 99999,
                            settings: "unslick"
                        }, {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                });
            });
        }
    }
});



